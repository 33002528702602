/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
require("stylesheets/style_application");

import "@creative-tim-official/argon-dashboard-free/assets/vendor/bootstrap/dist/js/bootstrap.bundle.min";
import "@creative-tim-official/argon-dashboard-free/assets/vendor/headroom.js/dist/headroom.min";
import "@creative-tim-official/argon-dashboard-free/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min";
import "@creative-tim-official/argon-dashboard-free/assets/js/argon";
import {} from "jquery-ujs";
import "./customjs";

require.context("../images", true);

$(function () {
  window.hourdiff = function (first, second) {
    return ((second - first) / (1000 * 60 * 60)).toFixed(1);
  };

  window.init_chart_doughnut = function () {
    var chart_doughnut_settings;
    if (typeof Chart === "undefined") {
      return;
    }
    if ($(".canvasDoughnut").length) {
      chart_doughnut_settings = {
        type: "doughnut",
        tooltipFillColor: "rgba(51, 51, 51, 0.55)",
        data: {
          labels: $(".canvasDoughnut").data("labels"),
          datasets: [
            {
              data: $(".canvasDoughnut").data("counts"),
              backgroundColor: $(".canvasDoughnut").data("background-color"),
              hoverBackgroundColor: $(".canvasDoughnut").data(
                "hoverbackground-color"
              )
            }
          ]
        },
        options: {
          legend: false,
          responsive: false
        }
      };
      $(".canvasDoughnut").each(function () {
        var chart_doughnut, chart_element;
        chart_element = $(this);
        chart_doughnut = new Chart(chart_element, chart_doughnut_settings);
      });
    }
  };

  window.init_calendar = function () {
    var calendar, categoryClass, d, date, m, started, y;
    if (typeof $.fn.fullCalendar === "undefined") {
      return;
    }
    date = new Date();
    d = date.getDate();
    m = date.getMonth();
    y = date.getFullYear();
    started = void 0;
    categoryClass = void 0;
    calendar = $("#calendar").fullCalendar({
      header: {
        left: "prev,next today",
        center: "title",
        right: "month,agendaWeek,agendaDay,listMonth"
      },
      selectable: true,
      selectHelper: true,
      select: function (start, end, allDay) {
        var ended;
        $("#fc_create").click();
        started = start;
        ended = end;
        $(".antosubmit").on("click", function () {
          var title;
          title = $("#title").val();
          if (end) {
            ended = end;
          }
          categoryClass = $("#event_type").val();
          if (title) {
            calendar.fullCalendar(
              "renderEvent",
              {
                title: title,
                start: started,
                end: end,
                allDay: allDay
              },
              true
            );
          }
          $("#title").val("");
          calendar.fullCalendar("unselect");
          $(".antoclose").click();
          return false;
        });
      },
      eventClick: function (calEvent, jsEvent, view) {
        $("#fc_edit").click();
        $("#title2").val(calEvent.title);
        categoryClass = $("#event_type").val();
        $(".antosubmit2").on("click", function () {
          calEvent.title = $("#title2").val();
          calendar.fullCalendar("updateEvent", calEvent);
          $(".antoclose2").click();
        });
        calendar.fullCalendar("unselect");
      },
      editable: true,
      events: $("#calendar").data("events")
    });
  };

  window.updateHalfDayOptions = function () {
    const started = $('input[name="absence[start]"]').val();
    const ended = $('input[name="absence[end]"]').val();
    const same_date = started === ended;

    if (same_date) {
      $("#morning-div").show();
      $("#afternoon-div").show();
    } else {
      $("#full-radio").prop("checked", true);
      $("#morning-div").hide();
      $("#afternoon-div").hide();
    }
  };
});
